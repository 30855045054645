<template>
  <div class="w-full mx-auto">
    <section aria-labelledby="candidate-dashboard">
      <div class="overflow-hidden bg-white rounded-lg shadow">
        <ul class="grid grid-cols-1 gap-x-4 gap-y-8">
          <li v-if="activeTab === 'aptitude'">
            <div
              v-if="aptitudeExams.length"
              class="space-y-8"
            >
              <AptitudeExamResults
                v-for="(exam, index) in aptitudeExams"
                :key="index"
                :candidate="candidate"
                :icon="dynamicIcon(exam)"
                :exam="exam"
                :index="index"
                :layout="layout"
              />
            </div>
            <ResultsEmpty
              v-else
              description="Aptitude test data will appear here"
            />
          </li>

          <li v-if="activeTab === 'drives'">
            <template v-if="drivesExams.length">
              <DrivesExamResults
                v-for="(exam, index) in drivesExams"
                :key="index"
                :icon="dynamicIcon(exam)"
                :exam="exam"
              />
            </template>
            <ResultsEmpty
              v-else
              description="Drives test data will appear here"
            />
          </li>

          <li v-if="activeTab === 'personality'">
            <template v-if="personalityExams.length">
              <PersonalityExamResults
                v-for="(exam, index) in personalityExams"
                :key="index"
                :icon="dynamicIcon(exam)"
                :exam="exam"
              />
            </template>
            <ResultsEmpty
              v-else
              description="Personality test data will appear here"
            />
          </li>

          <li v-if="activeTab === 'skills'">
            <template v-if="skillsExams.length">
              <!--
                @NB 2022-04-01 Some tests have graphs, some tests do not. Often
                skills tests do not have graphs (as it stands) and some of the
                older tests do, which means the skills tab can be inconsistent
                in that some have graphs and some don’t. In order to make it
                look more consistent, Guy has aksed that we don’t show graphs on
                this tab at all, so we over-ride the graph presentation in this
                instance.
              -->
              <AptitudeExamResults
                v-for="(exam, index) in skillsExams"
                :key="index"
                :icon="dynamicIcon(exam)"
                :candidate="candidate"
                :exam="exam"
                :index="index"
                :layout="layout"
                :show-graph="false"
              />
            </template>
            <ResultsEmpty
              v-else
              description="Skills test data will appear here"
            />
          </li>

          <li v-if="activeTab === 'customTests'">
            <div
              v-if="customExams.length"
              class="space-y-8"
            >
              <CustomExamResults
                v-for="(exam, index) in customExams"
                :key="index"
                :candidate="candidate"
                :exam="exam"
                :index="index"
                :layout="layout"
              />
            </div>
            <ResultsEmpty
              v-else
              description="Custom test data will appear here"
            />
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import AptitudeExamResults from '@components/Candidates/AptitudeExamResults'
import DrivesExamResults from '@components/Candidates/DrivesExamResults'
import PersonalityExamResults from '@components/Candidates/PersonalityExamResults'
import CustomExamResults from '@components/Candidates/CustomExamResults'
import ResultsEmpty from '@components/Candidates/ResultsEmpty'

import { examIcons } from '@data/examIcons'
import camelCase from 'lodash/camelCase'

export default {
  components: {
    AptitudeExamResults,
    DrivesExamResults,
    PersonalityExamResults,
    CustomExamResults,
    ResultsEmpty
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },
    activeTab: {
      type: String,
      default: ''
    },
    exams: {
      type: Array,
      default: () => []
    },
    layout: {
      type: String,
      default: 'large'
    }
  },

  data() {
    return {
      examIcons
    }
  },

  computed: {
    /**
     * @return {Array}
     */
    unlockedExams() {
      if (!this.exams) {
        return
      }

      return this.exams.filter(exam => exam.attempt.unlockedAt)
    },

    /**
     * @return {Array}
     */
    aptitudeExams() {
      // List of skill types that are not aptitude
      const skillTypes = [
        'soft-skills',
        'language',
        'software',
        'programming'
      ]

      if (!this.unlockedExams) {
        return
      }
      // For legacy reasons, aptitude is just ‘not one of the other types’
      // so this works out which ones are aptitude or not
      return this.unlockedExams.filter(exam => {
        if (exam.organisationId) {
          return false // Don’t include Custom Exam
        }
        if (exam.attempt.examType === 'aptitude') {
          return true
        }
        if (skillTypes.includes(exam.attempt.examType)) {
          return false // Type is one of the skill types
        }
        if (exam.attempt.examInstructionSlug.includes('personality')) {
          return false // Ignore personality tests
        }
        if (exam.attempt.examInstructionSlug === 'drives') {
          return false // Ignore Drives
        }
        return true // Otherwise an aptitude
      })
    },

    /**
     * @return {Array}
     */
    personalityExams() {
      if (!this.unlockedExams) {
        return
      }
      return this.unlockedExams.filter(exam => {
        return exam.attempt.examInstructionSlug.includes('personality')
      })
    },

    /**
     * @return {Array}
     */
    drivesExams() {
      if (!this.unlockedExams) {
        return
      }
      return this.unlockedExams.filter(exam => {
        return exam.attempt.examInstructionSlug === 'drives'
      })
    },

    /**
     * @return {Array}
     */
    skillsExams() {
      if (!this.unlockedExams) {
        return
      }
      return this.unlockedExams.filter(exam => {
        const skills = [
          'soft-skills',
          'language',
          'programming',
          'software'
        ]
        return skills.includes(exam.attempt.examType)
      })
    },

    /**
     * @return {Array}
     */
    customExams() {
      if (!this.unlockedExams) {
        return
      }
      return this.unlockedExams.filter(exam => {
        // If we have an organisationId, then it‘s a custom exam
        return !!exam.organisationId
      })
    }
  },

  methods: {
    /**
     * @param {Object} exam
     * @return {Object}
     */
    dynamicIcon(exam) {
      if (!exam.icon) {
        return () => import('@components/Icons/' + this.examIcons[exam.slug])
      }
      // Convert from lebab-case to UpperCamelCase
      let icon = camelCase(exam.icon)
      icon = icon.charAt(0).toUpperCase() + icon.slice(1)
      return () => import('@components/Icons/Exams/' + icon)
    }
  }
}
</script>
